@use '../../../styles/variables/colors' as colors;
.Availability {
  .MainContainer {
    background-color: colors.$white;
    height: auto;
    padding: 20px 20px 20px 20px;
    margin: 0px 50px 0px 30px;
    font-size: small;
  }
  .Message {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    margin-top: 50px;
  }
  .ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
  }
  .RowContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;  }
    .TextLabel {
      font-family: Montserrat, sans-serif;
      font-weight: normal;
      font-size: 15px !important;
      margin-top: 10px;
      color: colors.$black;  }
  .ArrowButtonLeft {
    background-color: transparent;
    border: none;
    transform: rotate(90deg);
    cursor: pointer;
  }
  .ArrowButtonRight {
    background-color: transparent;
    border: none;
    transform: rotate(270deg);
    cursor: pointer;
  }
  .DateHeader {
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 3px;
  }
  .GridContainer {
    flex-direction: column;
    text-align: center;
  }
  .Days {
    margin: 0;
  }
  .BoxButton {
    width: 30px;
    height: 50px;
    border: none;
    background-color: #e2e2e2;
    margin-top: 10px;
    padding: 0;
    cursor: pointer;
  }
  .Tile {
    width: 100%;
    height: 10%;
    background-color: colors.$green;
    border-top: 1px solid #bbbbbb;
    border-bottom: 1px solid #bbbbbb;
  }
  .WeekendBoxButton {
    width: 30px;
    height: 50px;
    border: none;
    background-color: #bbbbbb;
    margin-top: 10px;
    padding: 0;
    cursor: pointer;
  }
  // .ClickedBoxButton {
  //   width: 30px;
  //   height: 50px;
  //   border: none;
  //   background-color: #e2e2e2;
  //   margin-top: 10px;
  //   padding: 0;
  //   border: 1px;
  // }
  // For later
  // .UrlaubBoxButton {
  //   width: 30px;
  //   height: 50px;
  //   border: none;
  //   background-color: #808080;
  //   margin-top: 10px;
  // }
  .AddSlotContainer {
    padding: 15px 0 25px 0;
  }
  .MainSlotsContainer {
    margin-top: 50px;
  }
  .SplitRows {
    > div {
      border-top-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
    }
    > div:nth-child(1) {
      border-top-style: none;
    }
  }
  .ButtonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
  }
  .AddSlotButton {
    cursor: pointer;
    color: colors.$green;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    width: 185px;
    height: 15px;
    justify-content: space-between;
    align-items: center;
  }
  .PlusSymbol {
    position: relative;
    font-size: 30px;
    margin-top: 27px;
  }
  .ButtonClass {
    border-radius: 125px;
    margin-bottom: 10px;
    margin-right: 50px;
    font-size: small;
  }
  .InputClass {
    border-width: 0;
    background-color: colors.$light-gray;
    height: 30px;
    width: 200px;
  }
  .UhrInputClass {
    border-width: 0;
    background-color: colors.$light-gray;
    height: 30px;
    margin-right: 7px;
    width: 50px;
    text-align: center;
  }
  .UhrInputLabel {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-size: 15px !important;
    color: colors.$black;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .UhrRowContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: -15px;
  }
  .DeleteContainer {
    position: relative;
    top: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  .DeleteText {
    padding-left: 5px;
    font-size: 15px;
    color: rgb(220, 0, 0);
  }
}
