@use "src/styles/variables/colors" as colors;

.StatusMessage {
  margin: 8px 0;
}

.error {
  color: red;
}

.info {
  color: inherit;
}

.warning {
  color: yellow;
}

.success {
  color: green;
}