@use '../../../styles/variables/colors' as colors;
@import '../../../styles/variables/colors';

.AppointmentItems {
  font-family: Montserrat, sans-serif;

  &RowContainer {
    width: 100%;
    display: flex;
    gap: 7rem;
  }
  &ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 0.2rem;
  }
  &InputClass {
    border-width: 0;
    background-color: $light-gray;
    height: 25px;
  }
  &InvoiceInputClass {
    border-width: 0;
    background-color: $light-gray;
    height: 25px;
    width: 400px;
    font-family: 'Montserrat', sans-serif;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-style: italic;
    }
  }
  &TextArea {
    resize: none;
    border-width: 0;
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
    background-color: $light-gray;
    width: 100%;
    &::placeholder {
      font-style: italic;
    }
    &:focus {
      outline: none;
    }
    &::-webkit-scrollbar {
      background: transparent;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 50px;
    }
  }
  &TextAreaContainer {
    margin-left: 2rem;
  }
  &RoundedButton {
    border-radius: 20px;
  }
  &UnderlinedButton {
    text-decoration: underline;
    background-color: transparent;
    font-weight: bold;
    padding: 0;
  }
}

.Recipe {
  .BlackHeadings {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: normal;
    font-family: Montserrat;
  }

  .ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 25px;
    height: fit-content;
  }

  .RowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3rem;
  }

  .MainContainer {
    display: flex;
    flex-direction: row;
    padding: 5px 0;

    > div:nth-child(1) {
      flex: 6;
    }
    > div:nth-child(2) {
      flex: 2;
    }
  }

  .MedicineContainer {
    display: flex;
    flex-direction: row;
    > p:nth-child(1){
      width: 50%;
    }
    > p:nth-child(2){
      width: 50%;
    }
    > p:last-child{
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  .WhiteBoxContainer {
    background-color: colors.$white;
    height: auto;
    padding: 0 0 0 10px;
    width: auto;
  }

  .Container {
    background-color: colors.$white;
    height: auto;
    padding: 10px 10px;
    width: auto;
    > div:not(:last-child){
      border-bottom-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
      padding: 10px 0 10px 0;
    }
    > div:nth-child(1) {
      border-bottom-style: none;
      padding: 0;
    }
    > div:nth-child(2) {
      padding-top: 0;
      padding-bottom: 10px;
    }
  }
}

