.PrescriptionsHeader {
  &Div {
    display: flex;
    margin: 70px 0px 50px 20px;
  }
  &TitleDiv {
    margin-left: 25px;
  }
  &TitleText {
    font-weight: bold !important;
    font-family: Montserrat;
    font-size: 24px;
  }
  &TextDiv {
    display: flex;
  }
  &Text {
    margin: 10px 20px 0 0;
    font-weight: bold;
    color: gray;
  }
}
