@use '../../../styles/variables/colors' as colors;

.Patient {
  .PatientOverview {
    padding: 20px;
  }

  .AppointmentText {
    font-family: Montserrat, sans-serif;
    color: colors.$black;
    font-size: 15px;
    font-weight: bold;
    padding-left: 55px;
  }

  .RedAppointmentText {
    font-family: Montserrat, sans-serif;
    color: #ff0000;
    font-size: 15px;
    font-weight: bold;
    padding-left: 55px;
  }

  .NextAppointmentText {
    font-family: Montserrat, sans-serif;
    color: colors.$black;
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
    padding-left: 55px;
  }

  .PatientName {
    margin-right: 100px;
  }

  .ColumnContainer {
    display: flex;
    flex-direction: column;
  }

  .RowContainer {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
  }

  .MarginRight50 {
    margin-right: 60px;
  }

  .MarginRight70 {
    margin-right: 90px;
  }

  .MainContainer {
    display: flex;
    flex-direction: row;
  }

  .FirstContainer {
    flex: 6;
    display: flex;
    flex-direction: column;
    // margin-right: 30px;
  }

  .SecondContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
  }

  .NameAppointmentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .GeneralInfoContainer {
    background-color: colors.$white;
    height: 80%;
    padding: 20px 20px;
    margin-right: 25px;
  }

  .NameDiseaseContainer {
    border-bottom-style: solid;
    border-width: 2px;
    border-color: colors.$light-gray;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
  }

  .Flex5 {
    height: 100%;
    flex: 5;
  }

  .Flex2 {
    height: 100%;
    flex: 2;
  }

  .AppointmentContainer {
    background-color: colors.$white;
    height: 80%;
    padding: 20px 20px;
    width: auto;
  }

  .MedicationContainer {
    background-color: colors.$white;
    height: auto;
    width: auto;
    padding: 10px 20px;
    margin-bottom: 10px;

    >div {
      border-top-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
    }

    >div:nth-child(1) {
      border-top-style: none;
    }
  }

  .MedicineContainer {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    align-items: center;
  }

  .MedicineText {
    font-size: 15px;
    font-weight: bold;
    margin-right: 15px;
  }

  .MedicineValue {
    width: 20%;
    margin-left: 1rem;
  }

  .ChartContainer {
    background-color: colors.$white;
    margin-bottom: 10px;
  }

  .TimeOfIngestionText {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-size: 15px;
    color: colors.$black;
    margin: 0 0;
  }

  .DeclinePatientButtonContainer {
    margin: 25px;
    margin-left: -20px;
    text-align: start;
  }

  .DeclinePatientButton {
    background-color: #FF991A;
    border-radius: 125px;
    border-width: 0;
    color: colors.$white;
    font-weight: bold;
    font-size: 14px;
    padding: 10px 20px;
  }

  .DeclinePatientButtonDisabled {
    background-color: #FF991A75;
    border-radius: 125px;
    border-width: 0;
    color: colors.$white;
    font-weight: bold;
    padding: 10px 20px;
  }
}