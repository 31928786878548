@use '../../../styles/variables/colors' as colors;

.Medication {
  .MainContainer {
    background-color: colors.$white;
    height: auto;
    padding: 20px 15px 10px 15px;
    margin-bottom: 10px;
    width: fit-content;
    min-width: 100%;
  }

  .SpaceBetweenContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    margin-bottom: 10px;
  }

  .LeftRowContainer {
    display: flex;
    flex-direction: row;
    gap: 8rem;
  }

  .MedicineContainer {
    width: auto;
    font-size: small;
    > div {
      border-top-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
    }
    > div:nth-child(1) {
      border-top-style: none;
    }
  }

  .ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ColumnContainer > select {
    width: 500px;
  }

  .InputClass {
    border-width: 0;
    background-color: colors.$light-gray;
    height: 38px;
  }

  .SmallInputClass {
    border-width: 0;
    background-color: colors.$light-gray;
    height: 38px;
    width: 80px;
  }

  .DosageUnitContainer {
    font-size: 13px;
    align-self: flex-end;
    padding-bottom: 1rem;
  }

  .DeleteContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
    padding-bottom: 1rem;

    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  .DeleteText {
    height: 100%;
    display: flex;
    align-items: flex-end;
    font-size: 15px;
    color: rgb(220, 0, 0);
  }

  .AddMedicineContainer {
    padding: 10px 0 20px 0;
  }

  .AddMedicineButton {
    cursor: pointer;
    color: colors.$green;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    width: 185px;
    height: 15px;
    justify-content: space-between;
    align-items: center;
  }

  .PlusSymbol {
    position: relative;
    margin-top: 28px;
    font-size: 30px;
  }

  .ButtonContainer {
    margin-top: 45px;
    display: flex;
  }

  .ButtonClass {
    border-radius: 125px;
    padding: 10px 20px;
    font-size: small;
  }

  .ButtonLoading {
    border-radius: 125px;
    background-color: rgba(61, 179, 132, 0.5);
    padding: 10px 20px;
  }

  .MedicationHistoryRow {
    display: flex;
    padding: 15px 20px;
    background-color: colors.$white;

    div:nth-child(1) {
      flex: 2;
    }
    div:nth-child(2) {
      flex: 1;
    }
    div:nth-child(3) {
      flex: 1;
    }
    div:nth-child(4) {
      flex: 1;
    }
  }

  .prevMedications {
    margin-top: 30px;
    > div {
      margin-bottom: 20px;
    }
  }

  .ApothekeContainer {
    padding-bottom: 10px;
    background-color: colors.$white;
  }

  .NoPharmacyText {
    font-style: italic;
  }
}

.PreparationText {
  color: colors.$green;
}

.PreparationSubText {
  color: colors.$subtext;
  margin-left: 0.54rem;
}

.PreparationButton {
  border-radius: 125px;
  padding: 10px 20px;
  font-size: small;
  margin: 2rem 0 0 0;
}
