.Button {
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  cursor: pointer;

  &-primary {
    background-color: #3db384;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }

  &-secondary {
    background-color: white;
    color: #3db384 !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }
  &-disabled {
    background-color: white;
    color: #3db384 !important;
    cursor: not-allowed;
  }
}