@use './../../../styles/variables/colors.scss' as colors;

$paddingTopBottom: 3rem;

.PatientNavigation {
  &Container {
    display: flex;
    flex-direction: row;
    padding-left: 13px;
    height: 160px;
  }

  &NameHeading {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &RowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
  }

  &ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &MainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5rem;
  }

  &NameItemsContainers {
    height: 40px;
  }

  &NavItemsContainer {
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &Items {
    font-family: Montserrat, sans-serif;
    color: colors.$black;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
  }

  &SelectedItem {
    font-family: Montserrat, sans-serif;
    padding-bottom: 3px;
    border-bottom: 1px solid rgb(51, 51, 51);
    color: colors.$black;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
  }
}
