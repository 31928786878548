@import '../../../styles/variables/_colors.scss';

.Radio {
  &Wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &Input {
    appearance: none;
    display: none;
    padding: 2px;
    &:checked + div > div {
      transform: scale(1);
    }
  }
  &Delegate {
    border: 2px solid $green;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
  }
  &Indicator {
    transform: scale(0);
    transition: all 0.15s;
    background-color: $green;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
