@use "styles/index.scss";

html, body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
}

pre, code {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 1rem;
}


.popup-content  > div {
  z-index: 1 !important;
}