@import '../../../styles/variables/_colors.scss';

.CollapsibleCard {
  &MainContainer {
    background-color: $white;
    width: 100%;
    padding: 5px;
    margin-top: 5px;
  }
  &HeaderContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &HeaderDataContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 80px;
    width: 80%;
    padding-left: 10px;
  }
  &HoverIconContainer {
    background-color: transparent;
    transition: all 0.3s;
    cursor: pointer;
    padding: 7px;
    height: 50px;
    display: flex;
    align-items: center;
    & > div > svg > path {
      stroke: $green;
      transition: all 0.3s;
    }
    &:hover {
      background-color: $green;
      & > div > svg > path {
        stroke: #fff;
      }
    }
  }
  &HoverIcon {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    transform: rotate(-90deg);
  }
  &HoverIconRotated {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    transform: rotate(-180deg);
  }
  &ExpandContainer {
    border-top: 1px solid $green;
  }
}
