.Calendar {
	&Container {
		margin: 4%;
		height: calc(100vh - 10vh);
	}

	&Event {
		padding: 0.2rem;
		height: 200px;

		&PatientName {
			margin: 0.4rem 0;
		}
	}

	&Popup {
		padding: 20px 15px;
		background: white;
		width: 200px;
		box-shadow: 0px 2px 7px rgba(0,0,0,.5);
		border-radius: .5rem;

		&Name {
			font-weight: bolder;
			font-size: 1.3rem;
			margin-bottom: 15px;
		}

		&RegularDetails {
			font-size: 1.1rem;
			margin-bottom: 15px;
		}

		&BoldDetails {
			font-weight: bolder;
			font-size: 1.1rem;
		}

		&TerminType {
			font-weight: bolder;
			font-size: 1.1rem;
			margin-bottom: 20px;
		}

		&Button {
			padding: 8px 20px;
			border-radius: 15px;
			font-size: 1em;
			margin-bottom: 10px;
			&:focus {
				outline: none;
			}
		}
	}
	&Height {
		height: 90vh;
	}
}

