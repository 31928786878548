$paddingTopBottom: 6rem;
$paddingLeftRight: 3rem;

.navigation_container {
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: '#fff';
  height: calc(100vh - #{$paddingTopBottom} * 2);
  width: 30rem;
  min-width: 22rem;
  padding: $paddingTopBottom $paddingLeftRight;
}
  .details_container {
    display: flex;
    top: 0px;
    left: 300px;
    flex-direction: column;
    width: calc(100% - 300px);
    // min-width: 990px;
    position: relative;
    overflow: auto;
    height: calc(100vh);
    background-color: #f2f2f2;
  }