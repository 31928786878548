@use "../../styles/variables/colors" as colors;

.Patients {
    &MainDiv {
        margin-left: 20px !important;
        width: 100%;
    }
    &TableContainer {
        width: 85% !important;
        margin-top: 40px;
        min-width: 1000px !important;
    }
    &AlphabetButton {
        display: inline;
        color: gray;
        min-width: 20px;
        font-size: 16px;
        font-weight: bold;
        background-color: transparent;
        padding: 0.8rem !important;
        text-transform: uppercase;
        font-family: 'Montserrat' !important;
    }
    &TableCellHeaderMain {
        font-size: 20px !important;
        border-bottom: none !important;
        font-weight: bold !important;
        font-family: 'Montserrat' !important;
    }
    &TableCellHeader {
        color: gray !important;
        font-size: 16px !important;
        border-bottom: none !important;
        font-family: 'Montserrat' !important;
    }
    &TableCell {
        font-size: 15px !important;
        border-bottom: none !important;
        padding: 10px 16px 10px 16px !important;
        font-family: 'Montserrat' !important;
    }
    &TableCellGreen {
        font-size: 15px !important;
        border-bottom: none !important;
        padding: 10px 16px 10px 16px !important;
        font-family: 'Montserrat' !important;
        color: colors.$green !important;
    }
    &TableCellRed {
        font-size: 15px !important;
        border-bottom: none !important;
        padding: 10px 16px 10px 16px !important;
        font-family: 'Montserrat' !important;
        color: red !important;
    }
    &Input {
        background-color: colors.$light-gray;
        font-size: medium;
    }
    &Pointer {
        cursor: pointer;
    }
    &FlexContainer {
        display: flex;
    }
    &Id {
        flex: 1;
    }
    &Name {
        flex: 3;
    }
    &Birthday {
        flex: 2;
    }
    &NoBorder {
        border: none;
    }

}

