@use '../../../styles/variables/colors' as colors;

.Recipe {
  .BlackHeadings {
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .DescriptionText {
    font-size: 15px;
    font-weight: bold;
  }

  .LineHeight5 {
    line-height: '5px';
  }

  .InputClass {
    text-align: center;
    color: gray;
    border-radius: 125px;
    background-color: colors.$light-gray;
    height: 30px;
    margin: 0 15px;
    width: 60px;
  }
  .DropDown {
    text-align: center;
    color: gray;
    background-color: colors.$light-gray;
    height: 38px;
    margin: 0 5px;
    width: 50px;
    border: none;
  }

  .InputFreeText {
    font-size: 1.5rem;
    padding: 10px 12px;
    width: 100%;
    border-radius: 15px;
    margin-right: 30px;
    min-height: 90px;
    background-color: colors.$light-gray;
    box-sizing: border-box;
    resize: none;
    border: none;
    outline: none;
  }

  .ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 25px;
    height: fit-content;
  }

  .RowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6.5rem;
  }

  .MainContainer {
    display: flex;
    flex-direction: row;
    padding: 5px 0;

    > div:nth-child(1) {
      flex: 6;
    }
    > div:nth-child(2) {
      flex: 2;
    }
  }

  .FirstContainer {
    margin-bottom: 20px;
    background-color: colors.$white;
    height: auto;
    padding: 15px 20px;
    width: auto;
  }

  .GeneraInfoContainer {
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    align-items: center;
  }

  .MedicineContainer {
    display: flex;
    flex-direction: row;
    > p:nth-child(1){
      width: 70%;
    }
    > p:nth-child(2){
      width: 30%;
    }
  }

  .ButtonClass {
    height: 30px;
    padding: 0 20px;
    font-size: 13px;
    border-radius: 125px;
    margin-left: 50px;
  }

  .ButtonLoading {
    background-color: rgba(61, 179, 132, 0.5);
    height: 30px;
    padding: 0 20px;
    font-size: 13px;
    border-radius: 125px;
    margin-left: 50px;
  }

  .WhiteBoxContainer {
    background-color: colors.$white;
    height: auto;
    padding: 0 20px 0 20px;
    width: auto;
    > div {
      margin-bottom: 40px;
    }
    > div:nth-child(4) {
      border-bottom-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
      padding-bottom: 40px;
    }
  }

  .MedicinesContainer {
    > div {
      margin-bottom: 35px;
    }
  }

  .Container {
    background-color: colors.$white;
    height: auto;
    padding: 20px 20px;
    width: auto;
    > div {
      border-bottom-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
      padding: 20px 0;
    }
    > div:nth-child(1) {
      border-bottom-style: none;
      padding: 0;
    }
    > div:nth-child(2) {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }

  .MedicationContainer {
    background-color: colors.$white;
    height: auto;
    width: auto;
    padding: 5px 20px 20px 20px;
    margin-bottom: 10px;

    > div {
      border-top-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
    }
    > div:nth-child(1) {
      border-top-style: none;
      flex: 6;
    }
  }

  .GreyText {
    margin-bottom: 25px;
    font-size: 11px;
    text-align: end;
    color: rgb(77, 77, 77);
  }

  .MedicineText {
    font-size: 15px;
    font-weight: bold;
    margin: 20px 10px 0;
    padding-bottom: 10px;
  }

  .TimeOfIngestionText {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-size: 15px;
    color: colors.$black;
    margin: 0 0;
  }

  .ButtonsContainer {
    display: flex;
    justify-content: flex-end;
  }

  .GreenButton {
    height: 35px;
    width: 180px;
    font-size: 12px;
    border-radius: 125px;
    margin-left: 25px;
  }

  .RedButton {
    height: 34px;
    width: 180px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 125px;
    border-style: solid;
    border-width: 1px;
    color: rgb(193, 39, 45) !important;
  }

  .MedicineValue {
    margin-top: 10px;
    width: 20%;
  }
}
