@use './../../../styles/variables/colors' as colors;

.InputLoader {
  border: 2px solid colors.$light-gray;
  border-top: 2px solid colors.$green;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;

  &-primary {
    border: 2px solid colors.$light-gray;
    border-top: 2px solid colors.$green;
  }

  &-secondary {
    border: 2px solid colors.$green;
    border-top: 2px solid colors.$light-gray;
  }

  &-red-primary {
    border: 2px solid colors.$light-gray;
    border-top: 2px solid rgb(193, 39, 45) !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
