@import '../../../styles/variables/_colors.scss';

label {

  font-size: 14px;
}
.Checkbox {
  &Wrapper {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  &Input {
    appearance: none;
    display: none;
    &:checked + div {
      background-color: $green;
    }
  }
  &Delegate {
    border: 2px solid $green;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
