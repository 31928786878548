@use './../../styles/variables/colors' as color;

.Dashboard {
  .MainContainer {
    padding-top: 110px;
    display: flex;
    flex-direction: row;
    position: relative;
    left: 50px;

    > div:nth-child(1) {
      flex: 3;
      margin-right: 80px;
    }
    > div:nth-child(2) {
      flex: 4;
    }
  }

  .AppointmentsTitleContainer {
    display: flex;
    flex-direction: row;
    height: 100px;
    margin-bottom: 15px;

    button:hover {
      cursor: pointer;
    }

    > div:nth-child(2) {
      flex: 3;
      position: relative;
      top: 10px;
      margin-left: 20px;
    }
    > div:nth-child(3) {
      flex: 1;
      position: relative;
      top: 45px;

      > div:nth-child(1) {
        height: 25px;
        transform: rotate(90deg);
      }
      > div:nth-child(2) {
        height: 25px;
        transform: rotate(-0.25turn);
      }
    }
  }

  .MyApointmentsHeader {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .DateHeader {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 3px;
  }

  .TasksTitleContainer {
    font-family: Montserrat, sans-serif;
    display: flex;
    flex-direction: row;
    height: 100px;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 15px;
    > div:nth-child(2) {
      flex: 4;
      align-self: center;
      margin-left: 30px;
      position: relative;
      bottom: 10px;
    }
    > div:nth-child(3) {
      flex: 3;
      margin-left: 30px;
      align-self: center;
      position: relative;
      bottom: 20px;
    }
  }

  .searchInputClass {
    background-color: color.$light-gray;
  }

  .ColumnContainer {
    flex-direction: column;
  }

  .RowContainer {
    display: flex;
    flex-direction: row;
  }

  .CalendarContainer {
    position: relative;
  }

  .CalendarDay {
    font-family: Montserrat, sans-serif;
    position: absolute;
    top: 3rem;
    left: 2.7rem;
    font-size: 30px;
    font-weight: bold;
  }

  .ClockContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    color: rgb(251, 176, 59);
    font-size: 11px;
    > :nth-child(2) {
      margin-left: 8px;
    }
  }
  .AppointmentsContainers {
    background-color: color.$white;
    margin-top: 7px;
    padding: 15px 15px 15px 15px;
  }

  .AppointmentsRow1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: color.$white;
    margin-top: 7px;
    padding: 0 5px 0 15px;

    > div:nth-child(1) {
      flex: 2;
      position: relative;
    }
    > div:nth-child(2) {
      flex: 5;
    }
    > div:nth-child(3) {
      flex: 2;
    }
  }

  .setAppointmentStatusRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: color.$white;
    padding: 0 5px 0 15px;

    > div:nth-child(1) {
      flex: 2;
      position: relative;
    }
    > div:nth-child(2) {
      flex: 5;
      border-top-style: solid;
      border-width: 2px;
      border-color: color.$light-gray;
    }
    > div:nth-child(3) {
      flex: 2;
      border-top-style: solid;
      border-width: 2px;
      border-color: color.$light-gray;
    }
  }
  .SaveButton {
    height: 22px;
    padding: 0 10px;
    border-radius: 125px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 21px;
    border: 1px solid;
    color: color.$green;
    background-color: white;
  }

  .InputClass {
    border-width: 0;
    background-color: color.$light-gray;
    height: 20px;
    margin-top: 10px;
    margin-left: 20px;
  }

  .TasksContainer {
    width: 85%;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: color.$white;
    padding: 30px 25px 30px 25px;

    > div:nth-child(1) {
      flex: 3;
      font-size: 15px;
      font-weight: bold;
      margin-right: 5px;
    }
    > div:nth-child(2) {
      flex: 4;
      font-size: 15px;
    }
    > div:nth-child(3) {
      flex: 3;
    }
  }

  .AppointmentTimeNameText {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0;
  }

  .AppointmentTypeText {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    color: rgb(179, 179, 179);
    position: relative;
    margin-top: 8px;
  }

  .AppointmentText {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    position: relative;
    margin-top: 8px;
  }

  .ButtonClass {
    height: 22px;
    padding: 0 20px;
    border-radius: 125px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
  }

  .AppointmentsButtonClass {
    height: 22px;
    margin: 15px 0 20px 0;
    padding: 0 20px;
    border-radius: 125px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
  }

  .ArrowButton {
    border: none;
  }

  .DetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.NoClock {
      margin-bottom: 1rem;
    }
  }
}
