@use '../styles/variables/colors' as colors;

$paddingTopBottom: 6rem;
$paddingLeftRight: 3rem;

.NavigationDrawer {
  &Container {
    top: 0;
    left: 0;
    background-color: colors.$white;
    height: calc(100vh - #{$paddingTopBottom} * 2);
    width: 32rem;
    min-width: 22rem;
    padding: $paddingTopBottom $paddingLeftRight;
  }

  &Items {
    margin: 6rem 0 0 0;

    > :nth-child(even) {
      margin: 3rem 0;
    }
  }

  &Spacer {
    height: 0.2rem;
    width: 100%;
    background-color: colors.$light-gray;
    border-radius: 1rem;
  }

  &LogoutContainer {
    margin-top: 8rem;
  }
}