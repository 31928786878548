@use '../../../styles/variables/colors' as colors;

.Appointment {
  font-family: Montserrat, sans-serif;
  .MainContainer {
    width: 100%;

    > div {
      margin-bottom: 20px;
    }
  }

  .WhiteContainer {
    background-color: colors.$white;
    padding: 5px 20px 5px 20px;

    > div {
      border-top-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
    }
    > div:nth-child(1) {
      border-top-style: none;
    }
  }
  .InputClass {
    border-width: 0;
    background-color: colors.$light-gray;
    height: 25px;
    margin-top: 10px;
  }

  .ButtonClass {
    margin-top: 25px;
    border-radius: 125px;
  }

  .ButtonLoading {
    background-color: rgba(61, 179, 132, 0.5);
    margin-top: 25px;
    border-radius: 125px;
  }
}
